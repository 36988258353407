import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { TranslationService, i18n } from "@/services/TranslationService";
import { OAuthService } from "@/services/OAuthService.js";
import { AuthRoutesService } from "@/services/AuthRoutesService.js";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import startTokenWatcher from "@/services/TokenWatcher";

// Config
Vue.config.productionTip = false;

// Before Install Prompt Listener
// window.addEventListener('beforeinstallprompt', (event) => {
//     event.preventDefault();
//     store.commit('setInstallable', { isInstallable: true, deferredPrompt: event });
// });

// Register Components
Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

// Log Environment
console.log("Current environment: ", process.env.NODE_ENV);

// Auth Routes Initialization
AuthRoutesService.initialize();

// Create Vue instance
let vueInstance = new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: h => h(App),
}).$mount("#app");

// Translation Service Initialization
TranslationService.initialize(vueInstance);
startTokenWatcher();
