import { OAuthService } from "@/services/OAuthService";

const startTokenWatcher = async () => {
	console.log("🔄 Token watcher started. Checking every 5 minutes...");

	setInterval(async () => {
		// console.log("🕵️ Checking token expiration...");

		try {
			const isExpired = await OAuthService.validateTokenExpiry();

			if (isExpired) {
				console.warn("⚠️ Token expired! Re-authenticating...");
				localStorage.clear();
				sessionStorage.clear();
				if (window.indexedDB) {
					const databases = await window.indexedDB.databases();
					for (const db of databases) {
						await new Promise((resolve, reject) => {
							const request = window.indexedDB.deleteDatabase(db.name);
							request.onsuccess = resolve;
							request.onerror = reject;
						});
					}
				}
				window.location.reload();
			} else {
				// console.log("✅ Token is still valid.");
			}
		} catch (error) {
			console.error("❌ Error checking token expiration:", error);
		}
	}, 5 * 1000); // Every 5 minutes
};

export default startTokenWatcher;
