import Vue from "vue";
import Vuex from "vuex";
import Swal from "sweetalert2";
import { i18n } from "@/services/TranslationService";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// isInstallable: false,
		// deferredPrompt: null,
		PoiCategoryObject: null,
		userGroupName: null,
		categories: null,
		floors: null,
		poisByCategory: {}, // New state property
		categoryById: {},
		allPois: null,
		buildings: null,
		campuses: null,
		floorById: {},
		poiById: {},
		infoCategories: null,
		userProfile: null,
		buildingById: {},
		poisByBuildingId: {},
		poiCategoryByName: {},
		allGroups: null,
	},
	getters: {
		getPoiCategoryObject: state => state.PoiCategoryObject,
		getUserGroupName: state => state.userGroupName,
		getCategories: state => state.categories,
		getPoisByCategory: state => state.poisByCategory, // New getter
		getFloors: state => state.floors,
		getCategoryById: state => id => state.categoryById[id],
		getAllPois: state => state.allPois,
		getBuildings: state => state.buildings,
		getCampuses: state => state.campuses,
		getFloorById: state => id => state.floorById[id],
		getPOIById: state => id => state.poiById[id],
		getInfoCategories: state => state.infoCategories,
		getUserProfile: state => state.userProfile,
		getBuildingById: state => id => state.buildingById[id],
		getPoisByBuildingId: state => id => state.poisByBuildingId[id],
		getPoiCategoryByName: state => name => state.poiCategoryByName[name],
		getAllGroups: state => state.allGroups,
	},
	mutations: {
		resetState(state) {
			// Reset each state property
			// state.isInstallable = false;
			// state.deferredPrompt = null;
			state.PoiCategoryObject = null;
			state.userGroupName = null;
			state.categories = null;
			state.floors = null;
			state.poisByCategory = {};
			state.categoryById = {};
			state.allPois = null;
			state.buildings = null;
			state.campuses = null;
			state.floorById = {};
			state.poiById = {};
			state.infoCategories = null;
			state.userProfile = null;
			state.buildingById = {};
			state.poisByBuildingId = {};
			state.poiCategoryByName = {};
			state.allGroups = null;
		},
		// setInstallable(state, payload) {
		// 	state.isInstallable = payload.isInstallable;
		// 	state.deferredPrompt = payload.deferredPrompt;
		// },
		setPoiCategoryObject(state, PoiCategoryObject) {
			state.PoiCategoryObject = PoiCategoryObject;
		},
		setUserGroupName(state, userGroupName) {
			state.userGroupName = userGroupName;
		},
		setCategories(state, categories) {
			state.categories = categories;
		},
		SET_POIS_BY_CATEGORY(state, { categoryId, pois }) {
			// New mutation
			Vue.set(state.poisByCategory, categoryId, pois);
		},
		SET_FLOORS(state, floors) {
			state.floors = floors;
		},
		SET_CATEGORY_BY_ID(state, { id, category }) {
			Vue.set(state.categoryById, id, category);
		},
		SET_ALL_POIS(state, pois) {
			// This mutation will set all Pois in the state
			state.allPois = pois;
		},
		SET_BUILDINGS(state, buildings) {
			// This mutation will set all buildings in the state
			state.buildings = buildings;
		},
		SET_CAMPUSES(state, campuses) {
			// This mutation will set all campuses in the state
			state.campuses = campuses;
		},
		SET_FLOOR_BY_ID(state, { id, floor }) {
			Vue.set(state.floorById, id, floor);
		},
		SET_POI_BY_ID(state, { id, poi }) {
			Vue.set(state.poiById, id, poi);
		},
		SET_INFO_CATEGORIES(state, categories) {
			state.infoCategories = categories;
		},
		SET_USER_PROFILE(state, profile) {
			state.userProfile = profile;
		},
		SET_BUILDING_BY_ID(state, { id, building }) {
			Vue.set(state.buildingById, id, building);
		},
		SET_POIS_BY_BUILDING_ID(state, { buildingId, pois }) {
			Vue.set(state.poisByBuildingId, buildingId, pois);
		},
		SET_POI_CATEGORY_BY_NAME(state, { categoryName, category }) {
			Vue.set(state.poiCategoryByName, categoryName, category);
		},
		SET_ALL_GROUPS(state, groups) {
			state.allGroups = groups;
		},
	},
	actions: {},
	modules: {
		// Modules
	},
});
